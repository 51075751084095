* {
  margin: 0;
  padding: 0;

  --main-color: #af2264;
  --secondary-color: #150828;
  --purple-color: #261440;
  --turquoise-color: #46c1cb;
  --white: #fefefe
}

html,
body {
  font-family: "Roboto", sans-serif;
  height: 100%;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: #e1e1e1;
  /*background-image: url("img/bg-smarthack.jpg");*/
  background-position: right;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

/* #header {
  background-color: #e1e1e1;
  background-image: url("img/bg-smarthack.jpg");
  background-position: right;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
} */

ul {
  list-style-type: none;
  margin: 0 !important;
}

#euro {
  color: var(--main-color);
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: black;
}

.section,
.subSection {
  width: 100%;
}


#sponsori a {
  flex: 1;
  align-items: center;
}

#sponsori a img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.section-colored {
  background-color: var(--main-color);
}

.img-optimize-contrast {
  image-rendering: -webkit-optimize-contrast;
}

#header {
  position: relative;
}

#header .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 500ms ease;
}

#header .logo img {
  height: 50px;
  width: auto;
}

#header .logo.logoASMI>img {
  height: 40px;
}

#header nav a {
  letter-spacing: 2px;
  font-weight: 500;
}

#header nav img {
  height: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  display: block;
  max-width: 300px;
}

#header nav {
  height: 85px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: var(--secondary-color);
}

#header nav div#logoHeader {
  width: 30%;
  opacity: 1;
  transition: opacity 1s ease;
  cursor: pointer;
}

#header nav div.row ul#menu {
  width: 100%;
  margin: 0;
  display: flex;
  max-width: 100%;
  min-width: 100%;
}

#header nav div.row ul li {
  width: auto;
}

#header nav div.row ul li a:target {
  padding-top: 70px;
  /* = to height of navbar */
  margin-top: -70px;
}

#header>nav {
  position: fixed;
  z-index: 1000;
  transition: background-color 300ms ease;
}

.inscriere {
  box-sizing: border-box;
  border: solid 4px var(--main-color);
  border-radius: 0.5rem;
  border-width: 0.1rem;
  height: 3rem !important;
  color: white;
  box-shadow: 0 0 10px var(--main-color),
    0 0 10px var(--main-color),
    0 0 10px var(--main-color);
  transition: box-shadow 0.5s ease, transform 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inscriere:hover {
  box-shadow: 0 0 10px var(--main-color),
    0 0 20px var(--main-color),
    0 0 30px var(--main-color);
  transform: scale(1.1);
}

#header>nav .row {
  height: 100%;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  position: relative;
}

#header>nav #menu {
  display: flex;
  justify-content: flex-end;
}

#header>nav #menu>li {
  display: flex;
  align-items: center;
  height: 100%;
}

#header>nav #menu>li>a {
  display: flex;
  height: 100%;
  padding: 0 2em;
  align-items: center;
  color: #e1e1e1;
  text-transform: uppercase;
  transition: all 300ms ease;
  cursor: pointer;
}

#header>nav #menu>li>a.sticky {
  color: var(--secondary-color) !important;
}

#header>nav #menu>li>a:hover {
  color: var(--main-color);
}

#header>nav .hamburger {
  position: absolute;
  width: 60px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  right: 0;
  color: white;
  cursor: pointer;
  transition: all 500ms ease;
  display: none;

}

#header>nav .hamburger.sticky {
  color: var(--secondary-color);
}

#header #whiteBackground-gradient {
  width: 35%;
  height: 100%;
  background: #ffffff;
  /* Old browsers */
  background: #ffffff;
  /* Old browsers */
  background: -webkit-linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);
  background: -o-linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);
  background: linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  position: absolute;
  left: 0;
  float: right;
}

#header #whiteBackground-gradient>div {
  position: relative;
  width: 100%;
  height: 100%;
}

#header #whiteBackground-gradient>div #whiteBackground {
  height: 100%;
  width: auto;
  position: absolute;
  left: 99%;
}

#header #section1-container {
  min-height: 100vh;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#header #section1-container .row {
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#big-logo {
  display: flex;
  align-items: center;
  margin-top: 100px;
}

#big-logo img {
  width: 68%;
  height: auto;
  margin: 0 auto;
  display: block;
}

#header #section1-container .row #section1-info {
  max-width: 35% !important;
  margin-right: 3%;
}

#header #section1-container .row #section1-info p,
#header #section1-container .row #section1-info h1,
#header #section1-container .row #section1-info h2 {
  color: #e1e1e1;
  margin: 0;
}

#header #section1-container .row #section1-info h1 {
  font-size: 4.5vw;
  font-weight: 400 !important;
}

#header #section1-container .row #section1-info h2 {
  font-size: 15vw;
  font-weight: 300 !important;
  line-height: 90%;
  margin-left: -3%;
}

#header #section1-container .row #section1-info p {
  font-size: 1.5vw;
  font-weight: 300;
}

#header #section1-container .row #section1-info span {
  color: var(--main-color);
}

#header #section1-container .row #section1-info h3,
#header #section1-container .row #section1-info h4 {
  width: 100%;
  color: #e1e1e1;
  text-align: center;
}

#header #section1-container .row #section1-info h3 {
  font-size: 2vw;
  line-height: 2.5vw;
  margin-left: -1vw;
}

#header #section1-container .row #section1-info h3 span {
  margin-left: 2vw;
  font-weight: 400;
  font-weight: bolder;
}

#header #section1-container .row #section1-info h4 {
  font-size: 1.5vw;
}

#header #section1-container>.row #section1-info h4 i {
  color: var(--main-color);
  margin: 0 15px;
  font-size: 2vw;

}

#header #section1-container>.row:first-child {
  padding-bottom: 30px;
  margin-bottom: 60px;
  border-bottom: solid 5px var(--main-color);
}

.premiu>div {
  margin: auto;
}

#header #section1-container .row:nth-child(2) .timer li>span {
  font-weight: 300;
  margin-right: 1em;
  text-align: left;
  align-items: left;
}

#header #section1-container .row:nth-child(2) .timer li>span:first-child {
  font-size: 300%;
  line-height: 100%;
  margin-bottom: 10px;
  /* margin-left: 0.5em; */

}

#header #section1-container .row:nth-child(2) .timer li>span:last-child {
  font-size: 100%;
  margin-left: 0%;
  padding: 0%;
  text-align: left;
}

.altePremii {
  font-size: larger;
}

#header #section1-container .row:nth-child(2) .button {
  box-sizing: border-box;
  width: 100%;
  padding: 1% 0;
  border: solid 5px var(--main-color);
  color: var(--main-color) !important;
  font-weight: 700;
  font-size: 2vw;
  cursor: pointer;
  display: block;
  max-width: 33%;
  margin-right: 4%;
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  transition: background-color 300ms ease, border-color 300ms ease;
  border-radius: 1rem;
  background-color: #e1e1e188;
}

#header #section1-container .row:nth-child(2) .button:hover {
  border-color: #e1e1e1;
  background-color: #e1e1e1;
}

#start-concurs {
  font-weight: 300;
  font-size: 1.8vw;
  text-transform: none;
  color: var(--secondary-color);

}


#whiteBackground-substitution {
  position: fixed;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  /* Old browsers */
  background: #ffffff;
  /* Old browsers */
  background: -webkit-linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);
  background: -o-linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);
  background: linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  display: none;
}

.mobile-info {
  display: none;
}

section#despreSmarthack {
  padding: 2rem 12rem;
}

#despreSmarthack>h3 {
  position: relative;
  text-align: center;
  color: var(--main-color);
  top: 2rem;
  padding-bottom: 15px;
  margin-bottom: 70px;
  display: block;
  font-weight: bold;
  font-size: 3rem;
}

#despreSmarthack>h3 span {
  font-size: 2.7rem;
  /*  font-weight: 400 !important; */
  color: #e1e1e1;
}

#despreSmarthack>p {
  color: #e1e1e1;
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 30px;
}


#despreSmarthack {
  position: relative;
  background-image: url(img/1.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: auto;
  width: auto;
}

@media only screen and (max-width: 768px) {
  #despreSmarthack {
    background-attachment: scroll;
  }
}

#despreSmarthack>p h3 {
  padding: 30rem;
  z-index: 2;
  color: white;
}

@media (max-width:1000px) {
  section#despreSmarthack {
    padding: 2rem 2rem;
  }

  #despreSmarthack>h2 {
    position: relative;
    text-align: center;
    color: var(--main-color);
    top: 2rem;
    padding-bottom: 15px;
    margin-bottom: 70px;
    display: block;
    font-weight: bold;
    font-size: 3rem;
  }

  #despreSmarthack>h2 span {
    font-size: 2rem;
    font-weight: 400 !important;
    color: #e1e1e1;
  }

  #despreSmarthack>p {
    color: #e1e1e1;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
  }
}

#section2-container {
  margin-top: 0;
  background-color: #e1e1e1;
  padding: 2rem 10rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#section2-container .row.goals-row {
  /* margin-bottom: 40px; */
}

#section2-container .row.goals-row>div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#section2-container .row.goals-row>div>div>i {
  font-size: 4vw;
  margin-right: 15px;
  color: var(--secondary-color);
}

#section2-container .row.goals-row>div>div>i.fa-hourglass {
  font-size: 3.5vw;
}

#section2-container .row.goals-row>div>div>span {
  font-size: 4vw;
  color: var(--main-color);
}

.faq-card {
  padding-top: 20px;
  background: linear-gradient(#ededee 0%, #e0e0e0 100%);
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 80%;
}

#section2-container .row.despre-row {
  padding: auto;
}

#section2-container .row.despre-row>div>div {
  margin-bottom: 60px;
  padding: 10px 30px;
}

#section2-container .row.despre-row>div>div>h1 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 24px;
  color: var(--main-color);
}

#section2-container .row.despre-row>div>div>p {
  text-align: center;
  color: #203442;
}


.section-where-when {
  position: relative;
  background-image: url('img/bg-ai.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 50px 0;
  color: white;
}

@media only screen and (max-width: 768px) {
  .section-where-when {
    background-attachment: scroll;
  }
}

.section-where-when .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(264, 65%, 9%, 0.877);
  z-index: 1;
}

.section-where-when .container {
  position: relative;
  z-index: 2;
}



.section-where-when h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 3rem;
  font-weight: bold;
  margin: 4rem 0;
  color: white;
}

.middle {
  text-align: center;
  margin-bottom: 30px;
}

.program-wrapper {
  margin-top: 5rem;
  margin-left: 10rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem;
}

.program-grid {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.program-grid h3 {
  margin-bottom: 10px;
  font-size: 1.7em;
}

ul.program {
  list-style-type: none;
  padding: 0;
}

.program li {
  display: flex;
  margin-bottom: 10px;
}

.program-ora {
  min-width: 120px;
  font-weight: bold;
}

.program li div {
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .program-wrapper {
    margin-top: 5rem;
    margin-left: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .program-wrapper {
    grid-template-columns: 1fr;
    margin-right: -1rem;
    margin-left: 1.5rem;

  }

  .program-wrapper p {
    font-size: 1.rem;
  }
}

.section2-location {
  text-align: center;
}

.section2-location a {
  color: white;
}

/* SECTION 3 STARTS HERE */

#section3 {
  padding: 60px 0;
  margin-top: -10px;
  background-color: #e1e1e1;
}

#section3 .container .row>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#section3 .container .row h1 {
  padding-bottom: 15px;
  margin-bottom: 60px;
  position: relative;
  text-align: center;
}

#section3 .container .row h1:before {
  content: "";
  width: 40%;
  border-bottom: solid 1px var(--main-color);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#section3 .container .row ul li p i {
  transition: transform 300ms ease;
  margin-right: 7px;
}

#section3 .container .row ul li:hover>p>i {
  transform: rotate(-45deg);
}

.color-orange {
  color: var(--main-color);
}

.text-light {
  color: #fcfcfc;
}

#section4 {
  background-image: url("img/bg-poster.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  z-index: 99;
}

@media only screen and (max-width: 768px) {
  #section4 {
    background-attachment: scroll;
  }
}

#section4>p {
  width: 25%;
}

#section4 h2 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0rem 0;
  color: white;
  text-align: center;
}

#section4 .prise {
  width: 50%;
  position: relative;
}

#section4 .prise .money {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 10vw;
  line-height: 10vw;
  color: #fcfcfc;
}

#section4 .s-logo {
  color: var(--main-color);
  font-weight: bold;
  text-decoration: underline;
}

strong a {
  text-decoration: none;
  color: var(--main-color);
}

.faq-card>p>a {
  text-decoration: none;
  color: var(--main-color);
}

#why-to-participate {
  padding: 2rem;
  z-index: 99;
  text-align: center;
  background: #ffffff;
  background: -webkit-linear-gradient(#ffffff 0%, #ffffff 8%, #fcfcfc 16%, #e9e9ea 47%, #e1e1e1 65%, #e1e1e1 100%);
  background: -o-linear-gradient(#ffffff 0%, #ffffff 8%, #fcfcfc 16%, #e9e9ea 47%, #e1e1e1 65%, #e1e1e1 100%);
  background: linear-gradient(#ffffff 0%, #ffffff 8%, #fcfcfc 16%, #e9e9ea 47%, #e1e1e1 65%, #e1e1e1 100%);
}


.reasons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
  padding: 3rem;
}

.reason {
  display: flex;
  align-items: center;
  width: auto;
  min-width: 250px;
  position: relative;
}

.emoji-circle {
  width: 6rem;
  height: 6rem;
  color: #e1e1e1;
  background-color: var(--secondary-color);
  background: linear-gradient(350deg, var(--secondary-color), var(--main-color));
  border-radius: 50%;
  border: 0rem solid var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s ease, transform 0.5s ease;
}

.emoji-circle>i {
  font-size: 2.5rem;
}

.emoji-circle:hover {
  transform: scale(1.2);
  box-shadow: 0 0 20px #150828;
}

.reason p {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 968px) {
  #why-to-participate {
    align-content: center;
  }

  .reasons-container {
    flex-direction: column;
    align-items: center;
    padding-left: 20%;
    gap: 2rem;
  }

  .reason {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 400px;
    position: relative;
  }

  .emoji-circle {
    width: 5rem;
    height: 5rem;
  }

  .emoji-circle>i {
    font-size: 2.5rem;
  }

  .reason p {
    font-size: 1.rem;
  }
}

@media (max-width: 750px) {
  .reasons-container {
    padding-left: 5%;
  }

  .emoji-circle {
    width: 4rem;
    height: 4rem;
  }

  .emoji-circle>i {
    font-size: 1.8rem;
  }

  .reason p {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  #why-to-participate {
    margin: 0%;
    padding: 0%;
    align-items: center;
  }

  .reasons-container {
    flex-direction: column;
    padding-left: 0%;
    margin-left: 0%;
    margin: 0rem;
    padding: 2rem 0rem;
  }

  .reason {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    word-wrap: break-word;
    margin: 0rem;
  }

  .emoji-circle {
    width: 4rem;
    height: 4rem;
    margin: 0rem;
  }

  .emoji-circle>i {
    font-size: 2rem;
  }

  .reason p {
    font-size: 1.2rem;
    overflow-wrap: break-word;
    margin-top: 1rem;
    text-align: center;
  }
}


#section5 {
  position: relative;
  padding-top: 50px;
  z-index: 99;
  background: #ffffff;
  background: -webkit-linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);
  background: -o-linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);
  background: linear-gradient(#ffffff 0%,
      #ffffff 8%,
      #fcfcfc 16%,
      #e9e9ea 47%,
      #e1e1e1 65%,
      #e1e1e1 100%);


}

#section5 {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


#section5 .section-title {
  font-size: 3rem;
  font-weight: 700;
  color: var(--secondary-color);
  margin-top: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

#section5 .timer {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin-top: 2rem;
  width: 100%;
  max-width: 600px;
}

#section5 .timer li {
  text-align: center;
  margin: 0 15px;
}

#section5 .timer li .number {
  display: block;
  font-size: 4rem;
  font-weight: bold;
  color: var(--main-color);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

#section5 .timer li .label {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333;
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  #section5 .section-title {
    font-size: 2.5rem;
  }

  #section5 .timer li .number {
    font-size: 3rem;
  }
}


#section6 {
  min-height: 100%;
  padding-top: 40px;
  position: relative;
  z-index: 98;
  background-color: #e1e1e1;
}

#section6 h1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 60px;
  padding-bottom: 15px;
  position: relative;
  color: var(--main-color);
}

#section6 h1:before {
  content: "";
  width: 200px;
  border-bottom: solid 1px var(--main-color);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#section6 h2 {
  text-transform: uppercase;
  font-size: 20px;
  width: 100%;
  text-align: center;
  color: var(--main-color);
  background-color: #e1e1e1;
  display: inline-block;
  margin-bottom: 1rem;
  position: relative;
}

#section6 h2>span {
  background-color: #e1e1e1;
  position: relative;
  z-index: 2;
  padding: 0 15px;
}

#section6 h2:after {
  content: "";
  position: absolute;
  top: 0.5em;
  width: 100%;
  height: 1px;
  background-color: var(--main-color);
  left: 0;
  z-index: 0;
}

#section6 section>a {
  margin: 1rem 1.5rem 3rem;
}

#section6 section img {
  max-height: 90px;
  max-width: 100%;
  vertical-align: middle;
}

.partners h1 {
  text-align: center;
}

.partners h2 {
  text-align: center;
}

.main-partners,
.innovation-partners,
.recruiter-partners {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.main-partners img,
.innovation-partners img,
.recruiter-partners img {
  max-width: 100%;
  height: auto;
}



/*FREQUENTLY ASKED QUESTIONS*/
#section3 {
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: radial-gradient(at right center, #AF2265, #160929);
  color: #f5f5f5;
}

#section3 h2 {
  font-size: 3rem;
  font-weight: bold;
  margin: 4rem 0;
  color: white;
  text-align: center;
}

#section3 .faq-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  gap: 2rem;
}

#section3 .faq-column {
  width: 48%;
  display: flex;
  flex-direction: column;
}

#section3 .faq-new {
  margin-bottom: 2rem;
  border-bottom: 0.5px solid #9ea4b1;
  cursor: pointer;
}

#section3 .question {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

#section3 .question h5 {
  font-weight: 500;
  font-size: 1.2rem;
}

#section3 .answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding-top: 1rem;
  line-height: 1.6;
}

#section3 .answer a {
  color: white;
}

#section3 .answer a:hover {
  color: var(--secondary-color);
}

#section3 .answer p {
  color: #afb3bd;
}

.faq-new.active .answer {
  max-height: 1000px;
  animation: fade 0.3s ease-in-out;
}

svg {
  transition: transform 0.5s ease-in;

}

.faq-new.active svg {
  transform: rotate(180deg);
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  #section3 .faq-container {
    flex-direction: column;
    gap: 0;
  }

  #section3 .faq-column {
    width: 80%;
    align-self: center;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 480px) {
  #section3 h2 {
    font-size: 2rem;
  }

  #section3 .faq-new {
    margin-bottom: 1.25rem;
  }

  #section3 .question {
    font-size: 0.5rem;
  }

  #section3 .question h5 {
    font-size: 1.2rem;
  }

  #section3 .answer {
    font-size: 1.2rem;
  }
}

.main-partners {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 2rem;
}

.main-partners a {}

.main-partners a {
  margin-top: auto;
}

.innovation-partners {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}


@media (min-width: 758px) {
  .partners {
    margin-bottom: 1.5rem;
  }


  .innovation-partners {
    width: 100%;
  }

  .line2 {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .partners .innovation-partners>* {
    max-width: 10em;
    text-align: center;
  }


  .partners .innovation-partners>a>img {
    display: block;
    margin-left: auto;
    margin-right: auto;

  }

}

#section7 {
  padding-top: 3rem;
  position: relative;
  background-image: url("img/voluntari.jpg");
  background-position: right;
  background-size: cover;
  height: auto;
}

#section7::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 0, 2, 0.651);
  z-index: 1;
}

#section7 * {
  position: relative;
  z-index: 2;
  color: white;
}



#section7>div {
  width: 100%;
  height: 100%;
  background-image: url("img/footer-white-background.png");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#section7>div h1 {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
  display: inline-block;
  color: #e1e1e1;
}

#section7>div h1:before {
  content: "";
  width: 30%;
  border-bottom: solid 1px var(--main-color);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#section7>div p {
  color: #e1e1e1;
  text-align: center;
  margin-bottom: 30px;
}

#section7>div i {
  color: var(--main-color);
  font-size: 60px;
  margin-right: 30px;
}

#section7>div i.fa-envelope {
  font-size: 52px;
}

#section7>div a,
#section7>div span {
  color: var(--main-color) !important;
  font-size: 24px;
}

#section7>div .contact-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20%;
}

#section7>div .contact-links .contact-link {
  display: flex;
  align-items: center;
  padding: 12px 30px;
  transition: background-color 300ms ease;
  cursor: pointer;
}

#section7>div .contact-links .contact-link:hover {
  background-color: #e1e1e1;
}

@media (max-width: 1200px) and (max-height: 700px) {
  #section1-container>.row:first-child {
    margin-bottom: 30px !important;
    padding-top: 60px;
  }
}

@media (min-width: 1600px) and (max-height: 900px) {
  #section1-container>.row:first-child {
    margin-bottom: 30px !important;
    padding-top: 60px;
  }
}

@media (min-width: 1200px) and (max-height: 800px) {
  #section1-container>.row:first-child {
    margin-bottom: 30px !important;
    padding-top: 60px;
  }
}

@media (max-width: 1200px) {

  body {
    /* 
    background-image: url("img/bg-smarthackmobile.jpg"); */
    overflow-x: hidden;
    /* Varule, daca pui pe ambele axe nu mai poti da scroll in pa */

  }

  .mobile-info {
    background-image: url("img/bg-smarthackmobile.jpg");
    background-position: right;
    background-size: cover;
    background-attachment: scroll;
    height: 100%;
  }

  #section7 {
    /* 
    background-image: url("img/bg-smarthackmobile.jpg"); */
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    height: 100%;
  }

  #header nav {
    height: 80px;
  }

  #header nav a {
    letter-spacing: normal;
    font-weight: normal;
  }

  #header nav #menu>li>a {
    padding: 0 1.5em;
  }

  #start-concurs {
    font-size: 3vw;
  }
}

@media (min-width: 981px) and (max-width: 1200px) {
  #header nav {
    height: 70px !important;
  }

  #header nav #menu>li>a {
    height: 100%;
    padding: 0 1.5em;
    align-items: center;
  }
}

@media (min-width: 981px) {

  #header #section1-info {
    padding-top: 140px;
  }

  /* #header nav {
    background-color: #0a0f18e3;
  } */

  #amr {
    color: var(--secondary-color);
    padding: 0;
    margin-bottom: 2rem;

  }

  #header nav #menu {
    display: flex;
    justify-content: flex-end;
    height: 100% !important;
  }

  #header nav #menu>li {
    display: flex;
    align-items: center;
    height: 100%;
  }

  #section6 section>div {
    margin: 1rem 2rem;
  }
}

@media (max-width: 980px) {
  #header nav {
    background-color: var(--secondary-color);
    height: 4rem;
    padding: 0;
  }

  #header nav .hamburger {
    height: 4rem;
    padding: 0;
  }

  .main-partners>.row {
    width: 100%;


  }

  .main-partners>.row>a {
    padding-top: 20px;
    width: 80%;
    margin: auto;

  }

  .main-partners:nth-of-type(3)>.row>a:first-child {
    padding-top: 10px;
  }

  .main-partners:nth-of-type(3)>.row>a {
    padding-top: 0;
    padding-bottom: 20px;

  }

  .main-partners:nth-of-type(1)>.row>a:first-child {
    padding-bottom: 20px;

  }


  #header nav div#logoHeader {
    overflow: hidden;
    width: 96px;
  }

  #header nav div#logoHeader>img {
    content: url("/img/logos/smarthack-wide-white-mobile.png");
  }

  #header nav #menu {
    position: absolute;
    padding: 0;
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: flex-start;
    right: 15px;
    top: 0px;
    overflow: hidden;
    background-color: white;
    transition: all 300ms ease;
  }

  #header nav #menu>li {
    display: block;
  }

  #header nav #menu>li>a {
    height: 80px;
    padding: 0px 30px;
    color: var(--secondary-color) !important;
  }

  #header nav #menu>li.inscrie-te {
    height: auto;
  }

  #header nav .hamburger {
    display: flex;
  }

  #header nav .hamburger.active {
    color: var(--secondary-color);
    right: 15px;
  }

  #header nav .hamburger.active>i:before {
    content: "\f00d";
  }

  #section5 .container .partener-box {
    margin-bottom: 15px;
  }

  #section5 .container .partener-box>div>div {
    background-color: white;
  }

  #section5 .container .partener-box>div>div>a {
    margin-top: 30px !important;
  }
}

@media (max-width: 767px) {

  .logo,
  .logoASMI {
    display: none !important;
  }

  #whiteBackground-gradient {
    display: none;
  }

  #whiteBackground {
    display: none;
  }

  #header {
    height: auto !important;
    z-index: 101;
  }

  .mobile-info {
    display: block;
    position: relative;
    background-position: right;
    background-size: cover;
    background-attachment: scroll;
    min-height: auto;
    height: auto;
    align-items: center;
    padding: 60px 0;
  }

  .mobile-info>.container>.row {
    color: #e1e1e1;
  }

  .mobile-info>.container>.row>div h1,
  .mobile-info>.container>.row>div h2,
  .mobile-info>.container>.row>div h3 {
    font-weight: normal;
  }

  .mobile-info>.container>.row>div h1 {
    font-size: 11.5vw;
  }

  .mobile-info>.container>.row>div>a {
    /* content:url("https://inscriere.smarthack.asmi.ro/"); */
  }

  .mobile-info>.container>.row>div h1>span {
    color: var(--main-color);
  }

  .mobile-info>.container>.row>div h2 {
    font-size: 41.5vw;
    font-weight: 300;
    line-height: 35vw;
  }

  .mobile-info>.container>.row>div h2>span {
    color: var(--main-color);
  }

  .mobile-info>.container>.row>div h3 {
    text-align: center;
    font-size: 3vw;
    font-weight: 600;
    color: var(--main-color);

  }

  .mobile-info>.container>.row>div h3>span {
    color: var(--main-color);
    margin-right: 30px;
    font-weight: 600;
  }

  .mobile-info>.container>.row>div h4 {
    text-align: center;
    font-size: 3vw;
    font-weight: 200;
    color: var(--main-color);
  }

  .mobile-info>.container>.row>div h4>a {
    color: var(--main-color);
  }

  .mobile-info>.container>.row>div h4 i {
    margin: 0 2vw;
    color: #e1e1e1;
  }

  .mobile-info>.container>.row>div h4 p {
    margin: 0 2vw;
    color: #e1e1e1;
  }


  .button-inscriere {
    display: table !important;
    margin: 60px auto 0 !important;
    font-size: 16px !important;
    color: var(--main-color) !important;
    font-weight: 600 !important;
    padding: 12px 60px !important;
    text-transform: uppercase;
    border: solid 4px var(--main-color);
    transition: all 300ms ease;
    border-radius: 1rem;
    background-color: #e1e1e188
  }

  .button-inscriere:hover {
    background-color: #e1e1e1 !important;
    border: solid 4px #e1e1e1 !important;
  }

  #start-concurs {
    font-size: 5vw;
  }

  #containerTestimoniale {
    flex-direction: column;
    align-items: center;
  }

  #containerTestimoniale .cardTestimoniale {
    height: fit-content;
    max-width: none;
    width: 87vw;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  #header {
    min-height: 100%;
  }

  #section1-container {
    min-height: 100%;
  }
}

@media (max-width: 767px) {
  body>header {
    background-color: #e1e1e1;
  }

  #section1-container {
    position: relative !important;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #section1-container>.row {
    margin: 0 0 60px 0 !important;
  }

  #section1-container>.row img {
    width: 50% !important;
  }

  #section1-container>.row #section1-info {
    display: none !important;
  }

  #section1-container>.row .button-inscriere {
    display: none !important;
  }

  #section1-container>.row:nth-child(2) {
    justify-content: center !important;
  }

  #section1-container>.row:nth-child(2) .timer>li>span:first-child {
    font-size: 6vw !important;
  }

  #section1-container>.row:nth-child(2) .timer>li>span:last-child {
    font-size: 2vw !important;
  }

  #section2-container {
    margin-top: 120px !important;
  }

  #whiteBackground-substitution {
    display: initial !important;
  }

  .hamburger {
    color: var(--white) !important;
  }

  .hamburger.active {
    color: var(--secondary-color) !important;
  }


  .footer a,
  .footer span {
    font-size: 16px !important;
  }

  .footer i {
    font-size: 40px !important;
  }
}

@media (max-width: 480px) {
  #header #section1-container>.row:first-child {
    border-bottom: solid 4px var(--main-color);
  }

  #header #section1-container .timer {
    padding: 0;
  }

  #header #section1-container .timer>li>span:first-child {
    font-size: 10vw !important;
  }

  #header #section1-container .timer>li>span:last-child {
    font-size: 3vw !important;
  }

  #header #section1-container img {
    width: 80% !important;
    padding-bottom: 60px;
  }

  #header #section1-container .row {
    width: auto;
  }

  .mobile-info>.container div {
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-info h3,
  .mobile-info h4 {
    font-size: 5vw !important;

  }


  .mobile-info h3 span {
    position: relative;
  }

  .mobile-info h3 span:after {
    content: "";
    position: absolute;
    background-color: var(--main-color);
    width: 2vw;
    height: 2vw;
    top: 30%;
    right: -5.3vw;
    transform: rotate(45deg);
  }

  .mobile-info .inscriere-button {
    width: 100% !important;
    text-align: center;
    padding: 16px 0 !important;
  }

  #section2-container {
    padding: 2rem;
  }


  #section2-container>.goals-row i,
  #section2-container>.goals-row span {
    font-size: 45px !important;
  }

  #section2-container>.goals-row>div {
    margin-bottom: 15px;
  }

  #section4 {
    height: auto !important;
    min-height: 50%;
    padding: 60px 0 30px;
  }

  #section4>p {
    width: 90%;
  }



  #section4 .section-title {
    top: 0px;
    padding: 30px;
    font-size: 2em;
    margin-bottom: 0;
  }

  #section4 .prise>div {
    display: flex;
    flex-direction: column;
  }

  #section4 .prise>div>* {
    text-align: center;
  }

  #section4 .prise>div>a {
    padding-top: 10%;
  }

  #section4 .prise>div>a>* {
    max-width: 60%;

  }


  #section4>div {
    flex-direction: column;
  }

  #section4>div .prise {
    width: 100%;
    /* padding: 60px 0; */
  }

  #section4>div>div>.money {
    /* width: 100%; */
    padding: 2rem 0;
  }

  #section4>div .prise .sau {
    display: table;
    left: 33%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%) translateY(50%);
  }

  #section5>div {
    padding-top: 60px;
  }

  #section5 .button {
    display: block;
    padding: 12px 0 !important;
    margin: 60px 0 0 !important;
    max-width: none !important;
    width: 85% !important;
  }

  #section5 .partener-box img {
    max-height: 60px !important;
    max-width: 100%;
  }

  #section6 h2:after {
    top: auto;
    bottom: -7px;
  }

  #section6 div {
    min-height: 100px !important;
  }

  #section7>div .contact-links .contact-link {
    padding: 12px 0 !important;
  }

  .innovation-partners a {
    max-width: 7em;
  }

  #sponsori a {
    flex: none;
    max-width: 7em;
  }
}

#top,
.scrollTop {
  top: 93%;
  right: 1.5%;
  bottom: 0;
  position: fixed;
  z-index: 999;
}

.scrollTop:hover {
  cursor: pointer;
}




/* INSCRIERI CSS */
#section5 p {
  margin-bottom: 20px;
}

.section-title {
  color: #e1e1e1;
  position: relative;
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 70px;
  display: block;
}

#amr {
  color: var(--secondary-color);
  padding: 0;
  margin-bottom: 2rem;
}

#section5 .section-title {
  top: 60px;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card-container h2 {
  margin-bottom: 30px;
}

.card-container div ul {
  list-style-type: disc;
  list-style-position: inside;
}

.card-container div ul li {
  margin-bottom: 20px;
  color: var(--secondary-color);
}

#testimoniale {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--main-color);
  padding: 2em;
}

#testimoniale h2 {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  margin: 4rem 0;
  text-align: center;
}

#containerTestimoniale {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1vh 5vw 1vh 5vw;
}

.cardTestimoniale {
  display: flex;
  flex-direction: column;
  height: 27em;
  max-width: 25em;
  background: #ffffff;
  background: -webkit-linear-gradient(#ffffff 0%, #ffffff 12%, #fcfcfc 20%, #e9e9ea 47%, #e1e1e1 65%, #e1e1e1 100%);
  background: -o-linear-gradient(#ffffff 0%, #ffffff 12%, #fcfcfc 20%, #e9e9ea 47%, #e1e1e1 65%, #e1e1e1 100%);
  background: linear-gradient(#ffffff 0%, #ffffff 12%, #fcfcfc 20%, #e9e9ea 47%, #e1e1e1 65%, #e1e1e1 100%);
  padding: 1.5em;
  margin: 1em;
  border-radius: 4% 4% 4% 20%;
  transition: box-shadow 0.5s ease, transform 0.5s ease;
}

.cardTestimoniale:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px var(--secondary-color);
}

.cardTestimoniale h4 {
  font-weight: 600;
}

.titluCardTestimoniale {
  margin-bottom: 1em;
}

.ghilimeleTestimoniale {
  width: 2em;
  opacity: 15%;
}

.ghilimeleTestimoniale:nth-of-type(2) {
  align-self: flex-end;
  justify-self: center;
  transform: rotate(180deg);
}

.mesajCardTestimoniale {
  text-align: center;
}

/* registration button */
/* ---BEGIN--- */

#center-button {
  text-align: center;
  margin-top: 3rem;
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;

  border-radius: 50px;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-7 {
  background-image: linear-gradient(to right, #1a0a2c, var(--main-color), var(--main-color), #469ba1);
  box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}

/* registration button */
/* ---END--- */


@media (max-width: 1085px) and (min-width: 768px) {
  .cardTestimoniale {
    height: 30em;
  }
}

@media (max-width: 768px) {
  body {
    background-attachment: scroll;
  }

  #header {
    background-color: #e1e1e1;
    position: relative;
  }

  #testimoniale h2 {
    font-size: 2rem;
  }

  .cardTestimoniale {
    width: 80vh;
    margin: 1rem 0;
  }

  .cardTestimoniale h4 {
    font-size: 1.6rem;
  }

  .cardTestimoniale h6 {
    font-size: 1.2rem;
  }

  .cardTestimoniale>p {
    font-size: 1.2rem;
  }

}

@media (min-width:769px) {
  #header {
    background-color: #e1e1e1;
    background-image: url("img/bg-smarthack.jpg");
    background-position: right;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
  }
}